<template>
  <div class="StockChart">
    <div class="container top-space-to-boxes">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12">
          <div v-html="StockCharts.AvanzaStockChart"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "../../src/styles/_layout.scss";
import "../../src/styles/_stock-chart.scss";

const axios = require("axios");

export default {
  name: "StockChart",
  data: function() {
    return {
      StockCharts: {
        AvanzaStockChart: "",
      },
      baseUrl: process.env.VUE_APP_WEB_API,
    };
  },

  methods: {
    getStockCharts: function() {
      var self = this;
      axios
        .get(self.baseUrl + "api/StockChart/GetStockChart")
        .then(function(response) {
          self.StockCharts.AvanzaStockChart = response.data.value.avanzaStockChart;
        })
        .catch((error) => {
          console.log(error);
          this.error = true;
          alert(error);
        });
    },
  },

  mounted() {
    this.getStockCharts();
  },
};
</script>
